exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-index-tsx": () => import("./../../../src/pages/company/index.tsx" /* webpackChunkName: "component---src-pages-company-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-microcms-news-news-id-tsx": () => import("./../../../src/pages/news/{microcmsNews.newsId}.tsx" /* webpackChunkName: "component---src-pages-news-microcms-news-news-id-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacyPolicy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-projects-microcms-projects-projects-id-tsx": () => import("./../../../src/pages/projects/{microcmsProjects.projectsId}.tsx" /* webpackChunkName: "component---src-pages-projects-microcms-projects-projects-id-tsx" */),
  "component---src-pages-service-index-tsx": () => import("./../../../src/pages/service/index.tsx" /* webpackChunkName: "component---src-pages-service-index-tsx" */),
  "component---src-pages-simulation-index-tsx": () => import("./../../../src/pages/simulation/index.tsx" /* webpackChunkName: "component---src-pages-simulation-index-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

